import React from 'react';
import cn from "classnames";

interface IProps {
	day: string,
	time: string,
	currentRoom: {
		value: string,
		label: string,
	}
	pickedDateAndTime: (value: { time: string; day: string }) => void,
	pickedItem: any[],
	calendarEvents: any[],
	generateTimeSlots: () => void,
}

const TimeLabel = ({
   day,
   time,
   currentRoom,
   pickedDateAndTime,
	 pickedItem,
	 calendarEvents,
	 generateTimeSlots,
}: IProps) => {
	const currentDate = new Date();
	const currentDay = new Date(currentDate).getDate();
	const currentMonth = new Date(currentDate).getMonth() + 1;
	const isPicked = pickedItem.find((item) => item.time === time && item.day === day);

	const getActiveTime = (day: string) => {
		const timeSlots = generateTimeSlots() as unknown as string[];
		let activeTime: string[] = [];
		const filteredData = calendarEvents.filter((item) => item.day === day);
		filteredData.forEach(({ startTime, endTime }) => {
			const startIndex = timeSlots.indexOf(startTime);
			const endIndex = timeSlots.indexOf(endTime);

			activeTime = [...activeTime, ...timeSlots.slice(startIndex, endIndex)];
		});

		return activeTime;
	};

	const isActiveDate = (day: string, time: string) => {
		const isCurrentDay = calendarEvents.some((item) => item.day === day);
		if (isCurrentDay) {
			return getActiveTime(day).includes(time);
		}

		return false;
	};

	const isDisabledDate = () => {
		const [date, month] = day.split('.');
		if (currentMonth > Number(month)) {
			return false;
		}
		if (currentMonth === Number(month)) {
			if (currentDay > Number(date)) {
				return true;
			} else {
				return false;
			}
		}
	}

	const handleClick = (day: string, time: string) => {
		pickedDateAndTime({day, time});
	}

	return (
		<div
			className={cn('time', {
				'activeTime': isActiveDate(day, time),
			})}
		>
			<>
				{ isActiveDate(day, time) && <div className="closeIcon" /> }
				{!isActiveDate(day, time) && (
					<button
						className={cn('time-label-cell', {
							'isPicked': isPicked,
							'isDisabled': isDisabledDate(),
						})}
						disabled={isDisabledDate()}
						onClick={() => handleClick(day, time)}>
						<div>{time}</div>
					</button>
				)}
			</>
		</div>
	);
}

export default TimeLabel;