import React, {useEffect, useState} from 'react';
import axios from 'axios';

import Calendar from "../../components/Calendar/Calendar";
import Rooms from "../../components/Rooms/Rooms";
import Services from "../../components/Services/Services";
import User from "../../components/User/User";
import './styles.css';

const Home = () => {
	const [currentRoom, setRoom] = useState({value: 'Зал 1', label: 'Зал 1'});
	const [isChangedRoom, setIsChangedRoom] = useState(false);
	const [pickedItem, setPickedItem] = useState([]);
	const [step, setStep] = useState(1);
	const [formServiceState, setFormServiceState] = useState({
		helpAssistant: false,
		rentSquare: false,
		rentSquareWithCartridge: false,
		needParkingSpace: false,
		needCargoLift: false,
		additionalRequests: false,
	});
	const [formUserState, setFormUserState] = useState({
		firstName: '',
		secondName: '',
		phone: '',
		nickName: '',
	});

	useEffect(() => {
		if (isChangedRoom){
			setPickedItem([]);
			setIsChangedRoom(false);
		}
	}, [isChangedRoom]);

	const getRoomPrise = (count: number = 1) => {
		switch (currentRoom.value) {
			case "Зал 1":
				return count * 700;
			case "Зал 2":
				return count * 800;
			case "Зал 3":
				return count * 650;
			case "Гримерка":
				return count * 600;
			default:
				return count * 700;
		}
	}

	const getWeekStartAndEnd = () => {
		const today = new Date();
		const currentDayOfWeek = today.getDay();
		const startOfWeek = new Date(today);
		startOfWeek.setDate(today.getDate() - currentDayOfWeek + (currentDayOfWeek === 0 ? -6 : 1)); // Adjust for Sunday
		startOfWeek.setHours(1, 0, 0, 0);

		const endOfWeek = new Date(startOfWeek);
		endOfWeek.setDate(startOfWeek.getDate() + 6);


		return { start: startOfWeek, end: endOfWeek };
	};

	const [currentWeekStart, setCurrentWeekStart] = useState(getWeekStartAndEnd().start);
	const [currentWeekEnd, setCurrentWeekEnd] = useState(getWeekStartAndEnd().end);

	const [calendarEvents, setCalendarEvents] = useState([]);

	const adjustForDST = (eventDate: Date) => {
		const winterTimeChangeDate2023 = new Date('2023-10-27T00:00:00');
		const summerTimeChangeDate2024 = new Date('2024-03-31T00:00:00');

		if (eventDate >= winterTimeChangeDate2023 && eventDate < summerTimeChangeDate2024) {
			eventDate.setHours(eventDate.getHours() - 1);
		} else if (eventDate >= summerTimeChangeDate2024) {
			eventDate.setHours(eventDate.getHours() + 1);
		}

		return eventDate;
	}

	const startDate = currentWeekStart.toISOString();
	const endDate = currentWeekEnd.toISOString();

	const userAuth = async () => {
		try {
			const response = await axios.get('/api/insert', {
				params: {
					event: {
						summary: 'THIS IS SPARTA',
						description: 'THIS IS DESCRIPTION',
						start: {
							dateTime: new Date(),
							timeZone: 'Europe/Kiev',
						},
						end: {
							dateTime: new Date(),
							timeZone: 'Europe/Kiev',
						},
					}
				}
			});
			return response;
		} catch (err) {
			new Error('Failed Auth');
		}
	}

	const getCalendarEvents = async () => {
		try {
			const response =  await axios.get('/api/events', {
				params: {
					startDate,
					endDate,
				},
			});
			const events = response.data.items.filter((item: any) => {
				return item.summary.includes(currentRoom.value);
			}).map(function(item: any) {
				var eventTitle = item.summary.replace(/\(.*?\)/g, '').trim();
				const pickedDay = `${adjustForDST(new Date(item.start.dateTime)).getDate()}.${adjustForDST(new Date(item.start.dateTime)).getMonth() + 1}`
				const startTime = `${adjustForDST(new Date(item.start.dateTime)).getHours()}.${adjustForDST(new Date(item.start.dateTime)).getMinutes()}0`;
				const endTime = `${adjustForDST(new Date(item.end.dateTime)).getHours()}.${adjustForDST(new Date(item.end.dateTime)).getMinutes()}0`;
				return {
					title: eventTitle,
					day: pickedDay,
					startTime: startTime,
					endTime: endTime,
				};
			});
			setCalendarEvents(events);
		} catch (err) {
			new Error('Failed Data');
		}
	}

	useEffect(() => {
		getCalendarEvents();
	}, [startDate, currentRoom]);

	const handleChangeRoom = (value: { value: string, label: string }) => {
		setRoom(value);
		setIsChangedRoom(true);
	}

	const updateWeek = (direction: number) => {
		const newCurrentWeekStart = new Date(currentWeekStart);
		newCurrentWeekStart.setDate(newCurrentWeekStart.getDate() + direction * 7);
		setCurrentWeekStart(newCurrentWeekStart);

		const newCurrentWeekEnd = new Date(currentWeekEnd);
		newCurrentWeekEnd.setDate(newCurrentWeekEnd.getDate() + direction * 7);
		setCurrentWeekEnd(newCurrentWeekEnd);
	};

	const showTime = () => {
		return pickedItem.map((item: {time: string}) => item.time).toString();
	}

	const showDate = () => {
		// @ts-ignore
		return pickedItem.day;
	}

	const getAdditionService = () => {
		let sum = 0
		if (formServiceState.helpAssistant) {
			sum = sum + 200;
		}
		if (formServiceState.rentSquare) {
			sum = sum + 300;
		}
		if (formServiceState.rentSquareWithCartridge) {
			sum = sum + 800;
		}
		return sum;
	}

	return (
		<div className="App">
			<div className="container">
				{step === 1 && (
					<>
						<div className="month-navigation">
							<button className="prev-week switchButton" onClick={() => updateWeek(-1)}>
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd"
									      d="M13.0177 3.99906C12.5939 3.57535 11.9095 3.57535 11.4858 3.99906L6.49906 8.98579C6.07535 9.4095 6.07535 10.0939 6.49906 10.5177L11.4858 15.5044C11.9095 15.9281 12.5939 15.9281 13.0177 15.5044C13.4414 15.0807 13.4414 14.3962 13.0177 13.9725L8.8023 9.74629L13.0177 5.53093C13.4414 5.10722 13.4305 4.4119 13.0177 3.99906Z"/>
								</svg>
							</button>
							<div className="month-label">
								<>
									{currentWeekStart.toLocaleDateString()} - {currentWeekEnd.toLocaleDateString()}
								</>
							</div>
							<button className="next-week switchButton" onClick={() => updateWeek(1)}>
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd"
									      d="M6.97223 4.47355C6.56476 4.88103 6.56476 5.53926 6.97223 5.94674L11.0261 10.0006L6.97223 14.0545C6.56476 14.462 6.56476 15.1202 6.97223 15.5277C7.37971 15.9352 8.03794 15.9352 8.44542 15.5277L13.2411 10.732C13.6486 10.3245 13.6486 9.66628 13.2411 9.2588L8.44542 4.4631C8.04839 4.06608 7.37971 4.06608 6.97223 4.47355Z"/>
								</svg>
							</button>
						</div>
						<Rooms handleChangeRoom={handleChangeRoom}/>
						<Calendar
							pickedItem={pickedItem}
							setPickedItem={setPickedItem}
							currentRoom={currentRoom}
							currentWeekStart={currentWeekStart}
							currentWeekEnd={currentWeekEnd}
							calendarEvents={calendarEvents}
						/>
						{!!pickedItem.length && (
							<div className="footer">
								<div className="flex">
									<div className="info">
										Ви обрали: {currentRoom.value} |
									</div>
									<div className="info">
										день: {showDate()} час: {showTime()} |
									</div>
									<div className="info">
										ціна: {getRoomPrise(pickedItem.length)} грн
									</div>
								</div>
								<button
									className="order"
									onClick={() => setStep(step + 1)}
									disabled={!pickedItem.length}
								>
									Продовжити
								</button>
							</div>
						)}
					</>
				)}
				{step === 2 && (
					<Services
						formState={formServiceState}
						setFormState={setFormServiceState}
						step={step}
						setStep={setStep}
					/>
				)}
				{step === 3 && (
					<User
						formData={formUserState}
						setFormData={setFormUserState}
						step={step}
						setStep={setStep}
					/>
				)}
				{!!pickedItem.length && step !== 1 && (
					<div className="footer">
						<div className="flex">
							<div className="info">
								Ви обрали: {currentRoom.value}
							</div>
							<div className="info">
								день: {showDate()} час: {showTime()}
							</div>
							<div className="info">
								ціна: {getRoomPrise(pickedItem.length)} грн
							</div>
						</div>
						<div className="info">
							{getAdditionService() !== 0 && <span> Додаткові опції: {getAdditionService()} грн<br/></span>}
						</div>
						<div className="info">
							Загальна сумма: {getRoomPrise(pickedItem.length) + getAdditionService()} грн
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Home;