import React from 'react';
import Select from 'react-select';
import './styles.css';

interface IProps {
	handleChangeRoom: (item: any) => void,
}

const Rooms = ({ handleChangeRoom }: IProps) => {
	const options = [
		{ value: 'Зал 1', label: 'Зал 1' },
		{ value: 'Зал 2', label: 'Зал 2' },
		{ value: 'Зал 3', label: 'Зал 3' },
		{ value: 'Гримерка', label: 'Гримерка' },
	];

	return (
		<div className="hallSelectorWrapper">
			<div className="selectRoom">Обрати зал:</div>
			<Select
				options={options}
				defaultValue={options[0]}
				className="rooms"
				isSearchable={false}
				onChange={(item) => handleChangeRoom(item)}
				theme={(theme) => {
					return ({
					...theme,
					borderRadius: 4,
					border: '1px solid #C4B6A4',
					height: 38,
					colors: {
						...theme.colors,
						primary25: '#FAF3EB',
						primary: 'black',
					},
				})}}
			/>
		</div>
	);
};

export default Rooms;