import React from 'react';

import './styles.css';

const Services = ({ formState, setFormState, step, setStep }: any) => {
	const handleCheckboxChange = (name: string) => {
		setFormState((prevState: any) => ({
			...prevState,
			// @ts-ignore
			[name]: !prevState[name],
		}));
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		setStep(step + 1);
	};

	return (
		<div className="wrapperService">
			<div className="addService">
				додаткові сервіси
			</div>
			<form className="formService" onSubmit={handleSubmit}>
				<label className="label">
					<input
						type="checkbox"
						checked={formState.helpAssistant}
						onChange={() => handleCheckboxChange('helpAssistant')}
					/>
					Допомога асистента (200 грн/год)
				</label>

				<label className="label">
					<input
						type="checkbox"
						checked={formState.rentSquare}
						onChange={() => handleCheckboxChange('rentSquare')}
					/>
					Оренда Instax Square (300 грн)
				</label>

				<label className="label">
					<input
						type="checkbox"
						checked={formState.rentSquareWithCartridge}
						onChange={() => handleCheckboxChange('rentSquareWithCartridge')}
					/>
					Оренда Instax Square з картриджем на 10 фото (800 грн)
				</label>

				<label className="label">
					<input
						type="checkbox"
						checked={formState.needParkingSpace}
						onChange={() => handleCheckboxChange('needParkingSpace')}
					/>
					Мені потрібне паркомісце
				</label>

				<label className="label">
					<input
						type="checkbox"
						checked={formState.needCargoLift}
						onChange={() => handleCheckboxChange('needCargoLift')}
					/>
					Мені потрібен вантажний ліфт
				</label>

				<label className="label">
					<input
						type="checkbox"
						checked={formState.additionalRequests}
						onChange={() => handleCheckboxChange('additionalRequests')}
					/>
					Додаткові побажання (фон / спеціалізована зйомка / велика группа людей тощо)
				</label>
				<div className="buttons">
					<button className="order" onClick={() => setStep(step - 1)}>
						Назад
					</button>
					<button type="submit" className="order">
						Далі
					</button>
				</div>
			</form>
		</div>
	)
}

export default Services;