import React from 'react';

import TimeLabel from "./elements/TimeLabel";
import './calendar.css';

const Calendar = ({
  pickedItem,
  setPickedItem,
  currentRoom,
  currentWeekStart,
  currentWeekEnd,
	calendarEvents,
}: any) => {
	const createWeekArray = () => {
		const weekArray = [];

		for (let currentDate = new Date(currentWeekStart); currentDate <= currentWeekEnd; currentDate.setDate(currentDate.getDate() + 1)) {
			weekArray.push(`${new Date(currentDate).getDate()}.${new Date(currentDate).getMonth() + 1}`);
		}

		return weekArray;
	};

	const generateTimeSlots = () => {
		const onlyThirdRoom = currentRoom.value === 'Зал 3'
		let startTime = onlyThirdRoom ? 8.30 : 8;
		const endTime = onlyThirdRoom ? 19.30 : 19;

		return Array.from(
			{ length: endTime - startTime + 1 }, (_, index) => onlyThirdRoom ?
				`${startTime + index}0` : `${startTime + index}.00`
		);
	};

	const pickedDateAndTime = (value: { time: string; day: string }) => {
		const otherDay = pickedItem.find(
			(item: { time: string; day: string }) => item?.day !== value?.day
		);

		if (otherDay) {
			setPickedItem([value]);
		} else {
			const index = pickedItem.findIndex(
				(item: { time: string; day: string }) => item.time === value.time && item.day === value.day
			);

			if (index === -1) {
				setPickedItem([...pickedItem, value]);
			} else {
				const updatedPickedItem = [...pickedItem];
				updatedPickedItem.splice(index, 1);
				setPickedItem(updatedPickedItem);
			}
		}
	}

	return (
		<div className="calendar">
			<div className="days-row">
				{['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'].map((dayName, index) => (
					<div className="day" key={index}>
						{dayName}
						<div className="date">
							{createWeekArray()[index]}
						</div>
					</div>
				))}
			</div>
			<div className="time-event-grid">
				<div className="events-grid">
					{createWeekArray().map((day, index) => (
						<div key={index}>
							{generateTimeSlots().map((time, innerIndex) => (
								<TimeLabel
									key={innerIndex}
									day={day}
									time={time}
									currentRoom={currentRoom}
									pickedDateAndTime={pickedDateAndTime}
									pickedItem={pickedItem}
									calendarEvents={calendarEvents}
									generateTimeSlots={generateTimeSlots}
								/>
							))}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
export default Calendar;