import React from 'react';
import { NavLink } from 'react-router-dom';

import './styles.css';

const NavBar = () => (
	<nav className="navBarWrapper">
		<NavLink to="/">
			<div className="logo"/>
		</NavLink>
		<ul className="wrapper">
			<li className="list">
				<NavLink to="/location">Локації</NavLink>
			</li>
			<li className="list">
				<NavLink to="/equipment">Обладнання та реквізит</NavLink>
			</li>
			<li className="list">
				<NavLink to="/rules">Правила</NavLink>
			</li>
			<li className="list">
				<NavLink to="/calendar">Календар</NavLink>
			</li>
			<li className="list studio">
				<a href="www.instafram.com" target="_blank">
					@pssy.studio
				</a>
			</li>
		</ul>
	</nav>

);

export default NavBar;