import React from "react";

const User = ({formData, setFormData, step, setStep}: any) => {
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setFormData((prevData: any) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		setStep(step + 1);
	};

	return (
		<form className="wrapperService userWrapper" onSubmit={handleSubmit}>
			<label className="userLabel">
				First Name: <br/>
				<input
					type="text"
					name="firstName"
					value={formData.firstName}
					onChange={handleChange}
					placeholder="First Name:"
					required
				/>
			</label>

			<label className="userLabel">
				Second Name: <br/>
				<input
					type="text"
					name="secondName"
					value={formData.secondName}
					onChange={handleChange}
					placeholder="Second Name:"
					required
				/>
			</label>

			<label className="userLabel">
				Phone: <br/>
				<input
					type="text"
					name="phone"
					value={formData.phone}
					onChange={handleChange}
					placeholder="Phone:"
					required
				/>
			</label>

			<label className="userLabel">
				Social Nickname: <br/>
				<input
					type="text"
					name="nickName"
					value={formData.nickName}
					onChange={handleChange}
					placeholder="Social Nickname:"
				/>
			</label>
			<button className="order" onClick={() => setStep(step - 1)}>
				Назад
			</button>
			<button type="submit" className="order">
				Замовити
			</button>
		</form>
	);
};

export default User;